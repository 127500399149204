import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AppBar from "@mui/material/AppBar";
import ErrorMessages from "../components/ErrorMessages";
import { useSelector } from "react-redux";
import { selectCurrentSessionId } from "../ducks/app/session/selectors";
import { useTranslation } from "react-i18next";
import { selectIsOutdated } from "../ducks/app/version/selectors";
import { Alert, AlertTitle, Button } from "@mui/material";
import { useState } from "react";
import AddNewCargoDialog from "../pages/AddNewCargoPage";
import {
  selectCurrentRouteId,
  selectCurrentTruckId,
} from "../ducks/data/route-with-stops/selectors";
import { drivingUrl, worktimeUrl } from "../routes";
import { useAppDispatch } from "../redux-store";
import { loadRouteWithStops } from "../ducks/data/route-with-stops";

export default function Footer() {
  const [showAddNewCargoDialog, setShowAddNewCargoDialog] = useState(false);
  const location = useLocation();
  const sessionId = useSelector(selectCurrentSessionId);
  const routeId = useSelector(selectCurrentRouteId);
  const truckId = useSelector(selectCurrentTruckId);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation");

  if (!routeId) {
    return null;
  }

  const onCloseAddNewModal = (result?: {
    cargoId: string;
    pickupId: string;
  }) => {
    setShowAddNewCargoDialog(false);
    dispatch(loadRouteWithStops(routeId!));
  };

  const getValueFromPath = (path: string) => {
    if (path.includes("worktime")) return 0;
    if (path.includes("driving")) return 1;
    return -1;
  };
  const value = getValueFromPath(location.pathname);

  return (
    <Box sx={{ pb: 7 }}>
      <AppBar
        position="fixed"
        sx={{
          top: "auto",
          bottom: 0,
          left: 0,
          right: 0,
          minHeight: 70,
          bgcolor: "white",
        }}
      >
        <ErrorMessages />
        {showAddNewCargoDialog && (
          <AddNewCargoDialog
            sessionId={sessionId!}
            onClose={onCloseAddNewModal}
          />
        )}
        {sessionId && (
          <BottomNavigation showLabels value={value}>
            <BottomNavigationAction
              component={Link}
              to={worktimeUrl({
                routeId: routeId!,
                truckId: truckId!,
                sessionId,
              })}
              label={t("footer.menuItem_WORKTIME")}
              icon={<AccessAlarmIcon />}
            />
            <BottomNavigationAction
              component={Link}
              to={drivingUrl({
                routeId: routeId!,
                truckId: truckId!,
                sessionId,
              })}
              label={t("footer.menuItem_DRIVING")}
              icon={<LocalShippingOutlinedIcon />}
            />
          </BottomNavigation>
        )}
        <VersionOutdated />
      </AppBar>
    </Box>
  );
}

const VersionOutdated = () => {
  const isOutdated = useSelector(selectIsOutdated);
  const { t } = useTranslation("translation");
  if (!isOutdated) {
    return null;
  }
  const reloadClick = () => {
    window.location.reload();
  };
  return (
    <Alert
      severity="warning"
      action={
        <Button color="inherit" variant="outlined" onClick={reloadClick}>
          {t("footer.applicationOutdated.buttonText")}
        </Button>
      }
    >
      <AlertTitle>{t("footer.applicationOutdated.heading")}</AlertTitle>
      {t("footer.applicationOutdated.bodyText")}
    </Alert>
  );
};
