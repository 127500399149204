import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api, { createDecoder } from "../../api";
import { DriverRoutesResponse, driverRoutesResponseT } from "./types";
import {
  startCargoTimeTracking,
  stopCargoTimeTracking,
} from "../../app/cargo-time-tracking";
import { logoutSession } from "../../auth";

type State = {
  requestState: "idle" | "loading" | "success" | "error";
  data: DriverRoutesResponse | null;
  showCompletedDispatcherCreatedStops: boolean;
  selectedTrailerId: string | null;
  expandedRow: ExpandedRow | null;
};

export type ExpandedRow = {
  id: string;
  type: "driverCreatedOrder" | "dispatcherCreatedStop";
};

const initialState: State = {
  requestState: "idle",
  data: null,
  showCompletedDispatcherCreatedStops: false,
  selectedTrailerId: null,
  expandedRow: null,
};

const prefix = "data/route-with-stops";

export const loadRouteWithStops = createAsyncThunk(
  `${prefix}/load-route-with-stops`,
  async (routeId: string, thunkAPI) =>
    api(thunkAPI).get({
      url: `/drivers-api/routes/${routeId}`,
      decoder: createDecoder(driverRoutesResponseT),
    })
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setSelectedTrailerId: (state, action) => {
      state.selectedTrailerId = action.payload;
    },
    setShowCompletedDispatcherCreatedStops: (state, action) => {
      state.showCompletedDispatcherCreatedStops = action.payload;
    },
    expandRow: (state, action) => {
      state.expandedRow = action.payload;
    },
    collapseRow: (state) => {
      state.expandedRow = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(startCargoTimeTracking.fulfilled, (state, action) => {
      const cargo = state.data?.cargos.find(
        (c) => c.id === action.meta.arg.cargoId
      );
      if (cargo?.timeTracking) {
        cargo.timeTracking.started = action.meta.arg.data.time.toISO();
      }
    });
    builder.addCase(stopCargoTimeTracking.fulfilled, (state, action) => {
      const cargo = state.data?.cargos.find(
        (c) => c.id === action.meta.arg.cargoId
      );
      if (cargo?.timeTracking) {
        cargo.timeTracking.ended = action.meta.arg.data.time.toISO();
      }
    });
    builder.addCase(loadRouteWithStops.pending, (state) => {
      state.requestState = "loading";
    });
    builder.addCase(loadRouteWithStops.fulfilled, (state, action) => {
      state.requestState = "success";
      state.data = action.payload;
    });
    builder.addCase(loadRouteWithStops.rejected, (state) => {
      state.requestState = "error";
      state.data = null;
    });
    builder.addCase(logoutSession.fulfilled, (state) => {
      state.requestState = initialState.requestState;
      state.data = initialState.data;
      state.showCompletedDispatcherCreatedStops =
        initialState.showCompletedDispatcherCreatedStops;
      state.expandedRow = initialState.expandedRow;
    });
  },
});

export default slice.reducer;

export const {
  setShowCompletedDispatcherCreatedStops,
  setSelectedTrailerId,
  expandRow,
  collapseRow,
} = slice.actions;
