import { DriverNotificationText } from "../../../ducks/app/notifications/types";
import { useTranslation } from "react-i18next";

const InAppTextNotification = ({
  notification,
}: {
  notification: DriverNotificationText;
}) => {
  const { t } = useTranslation("notifications");

  return <div>{t(notification.data.text)}</div>;
};

export default InAppTextNotification;
