import "./TimeTracking.scss";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseIcon from "@mui/icons-material/Pause";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useSelector } from "react-redux";
import { selectCargoTimeTracking } from "../../../ducks/data/route-with-stops/selectors";
import { useAppDispatch } from "../../../redux-store";
import { useState } from "react";
import {
  pauseCargoTimeTracking,
  resumeCargoTimeTracking,
  startCargoTimeTracking,
  stopCargoTimeTracking,
} from "../../../ducks/app/cargo-time-tracking";
import { DateTime } from "luxon";
import { useParams } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress, DialogActions, TextField } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { useRoutePageContext } from "../RoutePageContext";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import TotalCargoDriverTime from "./TotalCargoDriverTime";

const TimeTracking = (input: { cargoId: string; stopId: string }) => {
  const { cargoId, stopId } = input;
  const { t } = useTranslation("stopPage");
  const dispatch = useAppDispatch();
  const [startRequestInProgress, setStartRequestInProgress] = useState(false);
  const [pauseResumeRequestInProgress, setPauseResumeRequestInProgress] =
    useState(false);
  const [stopRequestInProgress, setStopRequestInProgress] = useState(false);
  const [shouldShowEditStartedTimeDialog, setShouldShowEditStartedTimeDialog] =
    useState(false);
  const [shouldShowEditEndedTimeDialog, setShouldShowEditEndedTimeDialog] =
    useState(false);
  const params = useParams();
  const routeId = params.routeId as string;
  const truckId = params.truckId as string;
  const tracking = useSelector(selectCargoTimeTracking(input.cargoId));
  const { reloadData } = useRoutePageContext();

  if (!tracking) {
    return null;
  }

  const lastPausedResumedEvent = tracking.pauseResume.length
    ? tracking.pauseResume[tracking.pauseResume.length - 1]
    : null;

  const latestPause =
    tracking.pauseResume[tracking.pauseResume.length - 1]?.type === "PAUSE"
      ? tracking.pauseResume[tracking.pauseResume.length - 1]
      : null;

  const onStartTrackingClick = async () => {
    if (tracking.started) {
      setShouldShowEditStartedTimeDialog(true);
      return;
    }
    try {
      setStartRequestInProgress(true);
      await dispatch(
        startCargoTimeTracking({
          cargoId,
          data: {
            time: DateTime.now(),
            routeId,
            stopId,
            truckId,
          },
        })
      ).unwrap();
    } finally {
      setStartRequestInProgress(false);
    }
  };

  const onPauseTrackingClick = async () => {
    try {
      setPauseResumeRequestInProgress(true);
      await dispatch(
        pauseCargoTimeTracking({
          cargoId,
          data: {
            time: DateTime.now(),
            stopId,
            routeId,
            truckId,
          },
        })
      ).unwrap();
      reloadData();
    } finally {
      setPauseResumeRequestInProgress(false);
    }
  };

  const onResumeTrackingClick = async () => {
    try {
      setPauseResumeRequestInProgress(true);
      await dispatch(
        resumeCargoTimeTracking({
          cargoId,
          data: {
            time: DateTime.now(),
            routeId,
            stopId,
            truckId,
          },
        })
      ).unwrap();
      reloadData();
    } finally {
      setPauseResumeRequestInProgress(false);
    }
  };

  const onStopTrackingClick = async () => {
    if (tracking.ended) {
      setShouldShowEditEndedTimeDialog(true);
      return;
    }
    try {
      setStopRequestInProgress(true);
      await dispatch(
        stopCargoTimeTracking({
          cargoId,
          data: {
            time: DateTime.now(),
            routeId,
            stopId,
            truckId,
          },
        })
      );
    } finally {
      setStopRequestInProgress(false);
    }
  };

  const requestInProgress =
    startRequestInProgress ||
    pauseResumeRequestInProgress ||
    stopRequestInProgress;

  const pauseResumeEventsTimes = tracking.pauseResume.map((x) =>
    DateTime.fromISO(x.time)
  );

  return (
    <>
      {shouldShowEditStartedTimeDialog && (
        <EditTimeTrackingValueDialog
          type="START"
          cargoId={cargoId}
          stopId={stopId}
          dateTime={DateTime.fromISO(tracking.started!)}
          onClose={() => setShouldShowEditStartedTimeDialog(false)}
          pauseResumeEventsTimes={pauseResumeEventsTimes}
        />
      )}
      {shouldShowEditEndedTimeDialog && (
        <EditTimeTrackingValueDialog
          type="END"
          cargoId={cargoId}
          stopId={stopId}
          dateTime={DateTime.fromISO(tracking.ended!)}
          onClose={() => setShouldShowEditEndedTimeDialog(false)}
          pauseResumeEventsTimes={pauseResumeEventsTimes}
        />
      )}

      <div className="cargo-time-tracking">
        {tracking.started ? (
          <button
            disabled={requestInProgress}
            className="time-tracking-button flex-grow gray"
            onClick={onStartTrackingClick}
          >
            <div className="flex flex-col items-end">
              <div>{t("timeTracking.started")}</div>
              <div className="time-tracking-button__date">
                {DateTime.fromISO(tracking.started).toFormat("HH:mm")}
              </div>
            </div>
            <div>
              <EditOutlinedIcon style={{ fontSize: 22 }} />
            </div>
          </button>
        ) : (
          <button
            disabled={requestInProgress}
            className="time-tracking-button flex-grow green"
            onClick={onStartTrackingClick}
          >
            {startRequestInProgress ? (
              <LoadingSpinner />
            ) : (
              <PlayCircleOutlineIcon style={{ fontSize: 20 }} />
            )}
            <div>{t("timeTracking.start")}</div>
          </button>
        )}

        <button
          disabled={
            !!(requestInProgress || !tracking.started || tracking.ended)
          }
          onClick={
            lastPausedResumedEvent?.type === "PAUSE"
              ? onResumeTrackingClick
              : onPauseTrackingClick
          }
          className="time-tracking-button flex-grow yellow"
        >
          {lastPausedResumedEvent?.type === "PAUSE" ? (
            <>
              <div>
                {pauseResumeRequestInProgress ? (
                  <LoadingSpinner />
                ) : (
                  <PlayCircleOutlineIcon style={{ fontSize: 20 }} />
                )}
              </div>
              <div className="flex flex-col items-start">
                <div>{t("timeTracking.resume")}</div>
                <div className="time-tracking-button__date">
                  {DateTime.fromISO(lastPausedResumedEvent.time).toFormat(
                    "HH:mm"
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {pauseResumeRequestInProgress ? (
                <LoadingSpinner />
              ) : (
                <PauseIcon style={{ fontSize: 20 }} />
              )}
              <div>{t("timeTracking.pause")}</div>
            </>
          )}
        </button>

        {tracking.ended ? (
          <button
            disabled={requestInProgress || !!(!tracking.started || latestPause)}
            onClick={onStopTrackingClick}
            className="time-tracking-button flex-grow gray"
          >
            <div className="flex flex-col items-end">
              <div>{t("timeTracking.stopped")}</div>
              <div className="time-tracking-button__date">
                {DateTime.fromISO(tracking.ended).toFormat("HH:mm")}
              </div>
            </div>
            <div>
              {stopRequestInProgress ? (
                <LoadingSpinner />
              ) : (
                <EditOutlinedIcon style={{ fontSize: 22 }} />
              )}
            </div>
          </button>
        ) : (
          <button
            disabled={requestInProgress || !!(!tracking.started || latestPause)}
            onClick={onStopTrackingClick}
            className="time-tracking-button flex-grow red"
          >
            <FileDownloadDoneIcon style={{ fontSize: 20 }} />
            <div>{t("timeTracking.stop")}</div>
          </button>
        )}
      </div>
      <TotalCargoDriverTime cargoId={cargoId} />
    </>
  );
};

const LoadingSpinner = () => (
  <CircularProgress style={{ color: "white" }} size={22} />
);

const EditTimeTrackingValueDialog = (props: {
  type: "START" | "END";
  dateTime: DateTime;
  pauseResumeEventsTimes: DateTime[];
  cargoId: string;
  stopId: string;
  onClose: () => void;
}) => {
  const { onClose, cargoId, dateTime, type, pauseResumeEventsTimes, stopId } =
    props;
  const { t } = useTranslation("stopPage");
  const dispatch = useAppDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [dateTimeError, setDateTimeError] = useState<DateTime | null>(null);
  const [time, setTime] = useState<string>(dateTime.toFormat("HH:mm"));
  const [date, setDate] = useState<DateTime>(dateTime);

  const params = useParams();
  const routeId = params.routeId as string;
  const truckId = params.truckId as string;

  const onSaveClick = async () => {
    setIsSaving(true);
    setDateTimeError(null);
    const dateTimeNew = date.set({
      hour: parseInt(time.split(":")[0]),
      minute: parseInt(time.split(":")[1]),
    });

    if (pauseResumeEventsTimes.length) {
      if (type === "START") {
        const maxDateTime = DateTime.min(...pauseResumeEventsTimes);
        if (dateTimeNew > maxDateTime) {
          setDateTimeError(maxDateTime);
          setIsSaving(false);
          return;
        }
      } else {
        const minDateTime = DateTime.max(...pauseResumeEventsTimes);
        if (dateTimeNew < minDateTime) {
          setDateTimeError(minDateTime);
          setIsSaving(false);
          return;
        }
      }
    }

    try {
      const action =
        type === "START" ? startCargoTimeTracking : stopCargoTimeTracking;
      await dispatch(
        action({
          cargoId,
          data: { time: dateTimeNew, routeId, truckId, stopId },
        })
      ).unwrap();
      onClose();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Dialog open onClose={() => !isSaving && onClose()}>
      <div style={{ minWidth: 290 }}>
        <DialogTitle>{t("timeTracking.editDialogTitle")}</DialogTitle>
        <DialogContent>
          {dateTimeError && (
            <div style={{ color: "red", marginBottom: 10 }}>
              {t(
                `timeTracking.${type === "START" ? "errorStartTimeTooHigh" : "errorStopTimeTooLow"}` as any,
                {
                  dateTime: dateTimeError.toFormat("dd.MM.yyyy HH:mm"),
                }
              )}
            </div>
          )}
          <div>{t("timeTracking.date")}:</div>
          <TextField
            fullWidth
            type="date"
            margin="dense"
            value={date.toISODate()}
            onChange={(e) => {
              setDate(DateTime.fromISO(e.target.value));
            }}
          />
          <div>{t("timeTracking.time")}:</div>
          <TextField
            fullWidth
            margin="dense"
            type="time"
            value={time}
            onChange={(e) => {
              const time = DateTime.fromFormat(e.target.value, "HH:mm");
              setTime(time.toFormat("HH:mm"));
            }}
          />
        </DialogContent>
        <DialogActions className="mx-4 mb-2">
          <Button
            disabled={isSaving}
            onClick={onClose}
            variant="outlined"
            size="large"
            fullWidth
          >
            {t("timeTracking.cancel")}
          </Button>
          <LoadingButton
            disabled={isSaving}
            loading={isSaving}
            variant="contained"
            fullWidth
            size="large"
            onClick={onSaveClick}
          >
            {t("timeTracking.save")}
          </LoadingButton>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default TimeTracking;
