import React from "react";
import * as versionActions from "./ducks/app/version";
import { serializeError } from "serialize-error";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Settings as LuxonSettings } from "luxon";
import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import { reportErrorToBackend } from "./ducks/api";
import { store } from "./redux-store";
import { loadApiKeys } from "./ducks/app/api-keys";
// import { useLocation } from "react-router-dom";
import { isMobile, appVersion } from "./helpers/mobile";

// import {
//   createRoutesFromChildren,
//   matchRoutes,
//   useNavigationType,
// } from "react-router";

LuxonSettings.throwOnInvalid = true;
declare module "luxon" {
  interface TSSettings {
    throwOnInvalid: true;
  }
}

if (isMobile) {
  Sentry.init(
    {
      dsn: "https://0fbc4a701d3fcccc19a0d152daea2d3e@o4507542742368256.ingest.de.sentry.io/4508966066978896",
      // Set your release version, such as "getsentry@1.0.0"
      release: `dora-mobile-app@${appVersion}`,
      // Set your dist version, such as "1"
      dist: appVersion,
      integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        Sentry.browserTracingIntegration(),
        // Registers the Replay integration,
        // which automatically captures Session Replays
        Sentry.replayIntegration(),
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      // We recommend adjusting this value in production
      // Learn more at
      // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
      tracesSampleRate: 0.6,
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/staging\.doranordic\.dk/, /^https:\/\/app\.doranordic\.dk/],
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      // Learn more at
      // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    },
    // Forward the init method from @sentry/react
    SentryReact.init
  );
} else {
  SentryReact.init({
    release: "test-release",
    dsn: "https://ade179d0fa07ad6b5c3fbb92337cde8f@o4507542742368256.ingest.de.sentry.io/4507542785425488",
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/app.nordic\.dk/],
    integrations: [
      // Sentry.reactRouterV6BrowserTracingIntegration({
      //   useEffect,
      //   useLocation,
      //   useNavigationType,
      //   createRoutesFromChildren,
      //   matchRoutes,
      // }),
      SentryReact.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.6, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.NODE_ENV !== "development",
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

export const formatError = (err: any) =>
  err instanceof Error
    ? `${err.toString()}\n${err.message}\n${err.stack}`
    : JSON.stringify(err);

window.addEventListener("error", (event) => {
  const err = event.error || event;
  try {
    // Disabled for now, let's see if crossorigin helps
    // if (err?.message === "Script error.") {
    //   reportWarningToBackend({
    //     msg: "uncaught script error",
    //     err: serializeError(err),
    //   });
    // } else {
    reportErrorToBackend({
      msg: "uncaught error",
      err: serializeError(err),
    });
    // }
  } catch {
    // Ignore, don't want our unhandled error handler cause an unhandled error
  }
});

store.dispatch(versionActions.initialize((window as any).env.uiVersion));
store.dispatch(loadApiKeys());

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
