import type { CapacitorConfig } from "@capacitor/cli";

const config: CapacitorConfig = {
  appId: "dk.doranordic.app",
  appName: "DORA",
  webDir: "dist",
  server: {
    allowNavigation: ["*"],
    cleartext: true,
    androidScheme: "http",
  },
  ios: {
    contentInset: "never",
  },
  plugins: {
    CapacitorCookies: {
      enabled: true,
    },
    CapacitorHttp: {
      enabled: true,
    },
    CapacitorUpdater: {
      autoUpdate: true,
      appId: "dk.doranordic.app",
      // Do not change the "0.0.1" value, it is replaced by the version in the build process
      version: "0.0.1",
    },
    PushNotifications: {
      presentationOptions: ["badge", "sound", "alert"]
    }
  },
};

export default config;
