import { selectMe } from "../../ducks/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../redux-store";
import {
  selectMarkNotificationAsSeenInProgress,
  selectNotificationInView,
} from "../../ducks/app/notifications/selectors";
import "./InAppNotifications.scss";
import {
  loadNotifications,
  markNotificationAsSeen,
} from "../../ducks/app/notifications";
import "./InAppNotifications.scss";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import useFeature from "../../hooks/useFeature";
import { useTranslation } from "react-i18next";
import InAppTextNotification from "./notification-types/InAppTextNotification";

const InAppNotifications = () => {
  const { t } = useTranslation("notifications");
  const me = useAppSelector(selectMe);
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timeout | null>(null);
  const latestReceivedNotification = useAppSelector(selectNotificationInView);
  const markSeenInProgress = useAppSelector(
    selectMarkNotificationAsSeenInProgress
  );
  const driverInAppNotificationsEnabled = useFeature(
    "driver-in-app-notifications"
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (driverInAppNotificationsEnabled) {
      if (!intervalRef) {
        const r = setInterval(() => {
          dispatch(loadNotifications());
        }, 10000);
        setIntervalRef(r);
      }
    }
  }, [dispatch, driverInAppNotificationsEnabled, setIntervalRef, intervalRef]);

  if (!driverInAppNotificationsEnabled || !me || !latestReceivedNotification) {
    return null;
  }

  const onDismissNotificationClick = () => {
    dispatch(markNotificationAsSeen(latestReceivedNotification.id));
  };

  return (
    <div className="in-app-notifications">
      <div>
        {latestReceivedNotification.type === "TEXT" && (
          <InAppTextNotification notification={latestReceivedNotification} />
        )}
      </div>
      <br />
      <LoadingButton
        loading={markSeenInProgress}
        fullWidth
        onClick={onDismissNotificationClick}
        variant="contained"
      >
        {t("dismiss")}
      </LoadingButton>
    </div>
  );
};

export default InAppNotifications;
