import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  FinanceUnit,
  Product,
  Surcharge,
  InvoiceLine,
  PricingAgreement,
  financeDataT,
  pricingAgreementsT,
  createUpdateLineT,
  linesT,
  CreateUpdateInvoiceLine,
} from "./types";
import api, { createDecoder } from "../../api";
import { RootState } from "../../../redux-store";

type State = {
  financeData: {
    requestState: "idle" | "loading" | "success" | "error";
    products: Product[];
    surcharges: Surcharge[];
    units: FinanceUnit[];
  };
  pricingAgreements: {
    requestState: "idle" | "loading" | "success" | "error";
    data: PricingAgreement[];
  };
  invoiceLines: {
    requestState: "idle" | "loading" | "success" | "error";
    lines: InvoiceLine[];
  };
};

const initialState: State = {
  financeData: {
    requestState: "idle",
    products: [],
    surcharges: [],
    units: [],
  },
  pricingAgreements: {
    data: [],
    requestState: "idle",
  },
  invoiceLines: {
    lines: [],
    requestState: "idle",
  },
};

const prefix = "data/finance";

export const loadFinanceDataForDriver = createAsyncThunk(
  `${prefix}/load-finance-data-for-driver`,
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    // const loadingState = selectFinanceDataLoadingState(state);
    // TODO; this is set immediately, you can't check it in the action, refactor
    // if (loadingState === "loading") {
    //   return;
    // }
    return api(thunkAPI).get({
      url: "/drivers-api/finance",
      decoder: createDecoder(financeDataT),
    });
  }
);

export const loadPricingAgreements = createAsyncThunk(
  `${prefix}/load-pricing-agreements`,
  async (input: { deliveryId: string }, thunkAPI) => {
    const { deliveryId } = input;
    return api(thunkAPI).get({
      url: `/drivers-api/finance/${deliveryId}/pricing-agreements`,
      decoder: createDecoder(pricingAgreementsT),
    });
  }
);

export const loadInvoiceLines = createAsyncThunk(
  `${prefix}/load-invoice-lines`,
  async (input: { deliveryId: string }, thunkAPI) => {
    const { deliveryId } = input;
    return api(thunkAPI).get({
      url: `/drivers-api/finance/${deliveryId}/invoice-lines`,
      decoder: createDecoder(linesT),
    });
  }
);

export const createInvoiceLine = createAsyncThunk(
  `${prefix}/create-invoice-line`,
  async (
    input: { deliveryId: string; line: CreateUpdateInvoiceLine },
    thunkAPI
  ) => {
    const { deliveryId, line } = input;
    return api(thunkAPI).post({
      url: `/drivers-api/finance/${deliveryId}/invoice-lines`,
      body: createUpdateLineT.encode(line),
      decoder: createDecoder(linesT),
    });
  }
);

export const updateInvoiceLine = createAsyncThunk(
  `${prefix}/update-invoice-line`,
  async (
    input: {
      deliveryId: string;
      lineId: string;
      line: CreateUpdateInvoiceLine;
    },
    thunkAPI
  ) => {
    const { deliveryId, lineId, line } = input;
    return api(thunkAPI).put({
      url: `/drivers-api/finance/${deliveryId}/invoice-lines/${lineId}`,
      body: createUpdateLineT.encode(line),
      decoder: createDecoder(linesT),
    });
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadFinanceDataForDriver.pending, (state) => {
      state.financeData.requestState = "loading";
    });
    builder.addCase(loadFinanceDataForDriver.rejected, (state) => {
      state.financeData.requestState = "error";
    });
    builder.addCase(loadFinanceDataForDriver.fulfilled, (state, action) => {
      if (!action.payload) {
        return;
      }
      state.financeData.requestState = "success";
      state.financeData.products = action.payload.products;
      state.financeData.surcharges = action.payload.surcharges;
      state.financeData.units = action.payload.units;
    });
    builder.addCase(loadInvoiceLines.pending, (state) => {
      state.invoiceLines.requestState = "loading";
    });
    builder.addCase(loadInvoiceLines.fulfilled, (state, action) => {
      state.invoiceLines.requestState = "success";
      state.invoiceLines.lines = action.payload;
    });
    builder.addCase(createInvoiceLine.fulfilled, (state, action) => {
      state.invoiceLines.lines = action.payload;
    });
    builder.addCase(updateInvoiceLine.fulfilled, (state, action) => {
      state.invoiceLines.lines = action.payload;
    });
    builder.addCase(loadPricingAgreements.fulfilled, (state, action) => {
      state.pricingAgreements.requestState = "success";
      state.pricingAgreements.data = action.payload;
    })
  },
});

export default slice.reducer;
