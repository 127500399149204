import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Button } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditCargoTypeDialog from "../SessionsPage/StopPage/edit-dialogs/EditCargoTypeDialog";
import EditDescriptionDialog from "../SessionsPage/StopPage/edit-dialogs/EditDescriptionDialog";
import { CargoType } from "dora-contracts";
import EditClientDialog from "../SessionsPage/StopPage/edit-dialogs/EditClientDialog";
import { useSelector } from "react-redux";
import { useRoutePageContext } from "./RoutePageContext";
import { selectClientFromRoutesResponse } from "../../ducks/data/route-with-stops/selectors";

export const CargoInfoNew = ({
  cargoId,
  stopId,
  description,
  cargoType,
  shouldShowDriverEditButtons,
  clientId,
}: {
  cargoId: string;
  stopId: string;
  description: string | null;
  cargoType: CargoType | null;
  shouldShowDriverEditButtons?: boolean;
  clientId: string | null;
}) => {
  const { t } = useTranslation(["translation", "stopPage", "types"]);
  const [shouldShowEditCargoTypeDialog, setShouldShowEditCargoTypeDialog] =
    useState(false);
  const [shouldShowEditDescriptionDialog, setShouldShowEditDescriptionDialog] =
    useState(false);
  const [shouldShowEditCustomerDialog, setShouldShowEditCustomerDialog] =
    useState(false);

  const client = useSelector(selectClientFromRoutesResponse(clientId));

  const { reloadData } = useRoutePageContext();

  return (
    <div>
      {shouldShowEditCustomerDialog && (
        <EditClientDialog
          cargoId={cargoId}
          stopId={stopId}
          clientId={clientId}
          onClose={() => {
            reloadData();
            setShouldShowEditCustomerDialog(false);
          }}
        />
      )}
      {shouldShowEditCargoTypeDialog && (
        <EditCargoTypeDialog
          cargoId={cargoId}
          stopId={stopId}
          type={cargoType}
          onClose={() => {
            reloadData();
            setShouldShowEditCargoTypeDialog(false);
          }}
        />
      )}
      {shouldShowEditDescriptionDialog && (
        <EditDescriptionDialog
          stopId={stopId}
          cargoId={cargoId}
          desc={description}
          onClose={() => {
            reloadData();
            setShouldShowEditDescriptionDialog(false);
          }}
        />
      )}
      <div
        style={{
          padding: "12px 0 12px 16px",
          marginTop: "8px",
          background: "var(--primary-25)",
          fontSize: 14,
          color: "var(--primary-700)",
        }}
      >
        <div className="flex items-center justify-between">
          <div className="flex-grow">
            <span>{t("stopPage:cargoType")}</span>
            <span>:&nbsp;</span>
            <span>
              {cargoType ? t(`types:type`, { context: cargoType }) : ""}
            </span>
          </div>
          {shouldShowDriverEditButtons && (
            <Button onClick={() => setShouldShowEditCargoTypeDialog(true)}>
              <EditOutlinedIcon />
            </Button>
          )}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex-grow">
            {t(`stopPage:description`)}
            <span>:&nbsp;</span>
            <span>{description || ""}</span>
          </div>
          {shouldShowDriverEditButtons && (
            <Button onClick={() => setShouldShowEditDescriptionDialog(true)}>
              <EditOutlinedIcon />
            </Button>
          )}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex-grow">
            <span>
              {t("stopPage:client")}: {client?.name}
            </span>
          </div>
          {shouldShowDriverEditButtons && (
            <Button
              onClick={() => {
                setShouldShowEditCustomerDialog(true);
                reloadData();
              }}
            >
              <EditOutlinedIcon />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CargoInfoNew;
