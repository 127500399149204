import { useMemo } from "react";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { selectCargoTimeTracking } from "../../../ducks/data/route-with-stops/selectors";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { useTranslation } from "react-i18next";

const TotalCargoDriverTime = (input: { cargoId: string }) => {
  const { t } = useTranslation("stopPage");
  const tracking = useSelector(selectCargoTimeTracking(input.cargoId));

  const totalTime: {
    time: {
      hours: number;
      minutes: number;
    };
    break: {
      hours: number;
      minutes: number;
    } | null;
  } | null = useMemo(() => {
    if (!tracking || !tracking.started) {
      return null;
    }
    const startMoment = DateTime.fromISO(tracking.started);
    const endMoment = tracking.ended
      ? DateTime.fromISO(tracking.ended)
      : DateTime.now();

    const pauseResumePairs = tracking.pauseResume.reduce(
      (acc, cur, i, arr) => {
        if (i % 2 === 0) {
          acc.push({
            pause: cur,
            resume: arr[i + 1],
          });
        }
        return acc;
      },
      [] as {
        pause: (typeof tracking.pauseResume)[0];
        resume?: (typeof tracking.pauseResume)[0];
      }[]
    );

    const totalPauseTime = pauseResumePairs.reduce((acc, pair) => {
      const resumeTime = pair.resume
        ? DateTime.fromISO(pair.resume.time)
        : endMoment;
      return (
        acc + resumeTime.diff(DateTime.fromISO(pair.pause.time)).as("seconds")
      );
    }, 0);

    const totalTimeInSeconds =
      endMoment.diff(startMoment).as("seconds") - totalPauseTime;
    const hours = Math.floor(totalTimeInSeconds / 3600);
    const minutes = Math.floor((totalTimeInSeconds % 3600) / 60);

    const hoursBreak = Math.floor(totalPauseTime / 3600);
    const minutesBreak = Math.floor((totalPauseTime % 3600) / 60);

    return {
      time: { hours, minutes },
      break:
        hoursBreak || minutesBreak
          ? {
              hours: hoursBreak,
              minutes: minutesBreak,
            }
          : null,
    };
  }, [tracking]);

  if (!totalTime) {
    return null;
  }

  return (
    <div
      className="flex items-center gap-4 mt-4"
      style={{ color: "var(--gray-600)", fontSize: "14px" }}
    >
      <AccessAlarmIcon style={{ fontSize: 20 }} />
      <div>
        <span>
          {t("timeTracking.worktime")}
          <span>:</span>{" "}
          {totalTime.time.hours ? (
            <span>
              {totalTime.time.hours}
              {t("timeTracking.hours")}
            </span>
          ) : null}{" "}
          <span>
            {totalTime.time.minutes}
            {t("timeTracking.minutes")}
          </span>
        </span>
        {totalTime.break && (
          <span>
            {" + "}
            {t("timeTracking.break")}
            <span>:</span>{" "}
            {totalTime.break.hours ? (
              <span>
                {totalTime.break.hours}
                {t("timeTracking.hours")}
              </span>
            ) : null}{" "}
            <span>
              {totalTime.break.minutes}
              {t("timeTracking.minutes")}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default TotalCargoDriverTime;
