import React, { useEffect } from "react";
import { selectMe, selectAuthMode, selectStatus } from "./ducks/auth/selectors";
import { useSelector } from "react-redux";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { isMobile } from "./helpers/mobile";
import { apiFetch } from "./helpers/apiRequest";
import { Device } from "@capacitor/device";
import { browserSessionId } from "./ducks/api";
import useFeature from "./hooks/useFeature";
import { pushNotificationTokenInputT } from "dora-contracts";

const initNotifications = async () => {
  FirebaseMessaging.addListener("notificationReceived", (event) => {
    console.log("notificationReceived: ", { event });
  });
  FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
    console.log("notificationActionPerformed: ", { event });
  });

  const permissionResponse = await FirebaseMessaging.requestPermissions();
  console.log("permissionResponse: ", { permissionResponse });
  if (permissionResponse.receive === "denied") {
    console.warn("Permission for receiving notifications was denied");
    return;
  }

  const token = (await FirebaseMessaging.getToken()).token;
  const deviceId = (await Device.getId()).identifier;
  const platform = (await Device.getInfo()).platform;

  if (platform === "web") {
    return;
  }

  await apiFetch("/drivers-api/push-notifications/token", {
    method: "POST",
    headers: { "Content-Type": "application/json", browserSessionId },
    body: JSON.stringify(
      pushNotificationTokenInputT.encode({
        fcmToken: token,
        deviceId,
        platform,
      })
    ),
  });
};

const RegisterPushNotificationsListener: React.FC<any> = () => {
  const state = useSelector(selectStatus);
  const me = useSelector(selectMe);
  const authMode = useSelector(selectAuthMode);
  const mobilePushNotifications = useFeature("mobile-push-notifications");
  useEffect(() => {
    // Save token when we are logged in
    if (
      isMobile &&
      !!authMode &&
      state === "AUTHENTICATED" &&
      mobilePushNotifications
    ) {
      initNotifications();
    }
  }, [state, me, authMode, mobilePushNotifications]);

  return null;
};

export default RegisterPushNotificationsListener;
