import React, { SyntheticEvent, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { Stop } from "../../../ducks/app/session/types";
import { addWeight } from "../../../ducks/app/session";
import { selectMeasuredStopWeight } from "../../../ducks/app/session/selectors";
import { useAppDispatch } from "../../../redux-store";
import Decimal from "decimal.js-light";
import { useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import { selectCurrentRouteId } from "../../../ducks/data/route-with-stops/selectors";
import { loadRouteWithStops } from "../../../ducks/data/route-with-stops";

const formatWeight = (input: string | null) => input && `${input} kg`;

const AddWeightModal = (props: {
  sessionId: string;
  stop: Stop;
  onClose: () => void;
}) => {
  const { sessionId, stop, onClose } = props;
  const [weight, setWeight] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const currentWeight = useSelector(selectMeasuredStopWeight(stop.id));
  const { t } = useTranslation("stopPage");

  const currentRouteId = useSelector(selectCurrentRouteId);

  const onAddClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        addWeight({
          sessionId,
          cargoId: stop.cargo_id,
          stopId: stop.id,
          weight: new Decimal(weight),
        })
      );
      dispatch(loadRouteWithStops(currentRouteId!));
      onClose();
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog open onClose={onClose}>
      <div className="px-8 py-4">
        <Typography variant="h6" component="h2" pt={2}>
          {t("weight.heading")}
        </Typography>
        <Typography variant="body1" component="div">
          <p>
            {t("weight.plannedLabel") + " "}
            {formatWeight(stop.weight) || t("weight.unspecified")}
          </p>
          <p>
            {t("weight.actualLabel") + " "}
            {formatWeight(currentWeight) || t("weight.unspecified")}
          </p>
        </Typography>
        <Box component="form" mt={2} onSubmit={onAddClick}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                type="number"
                label={t("weight.textFieldLabel")}
                placeholder="1000"
                onChange={(e) => {
                  setWeight(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {t("weight.textFieldAdornment")}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="flex gap-2 justify-between w-100 mt-4">
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={onClose}
                >
                  {t("notes.backButtonLabel")}
                </Button>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                >
                  {t("weight.addButtonLabel")}
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Dialog>
  );
};

export default AddWeightModal;
