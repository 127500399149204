import { StopNew } from "../../../ducks/data/route-with-stops/types";
import HomeIcon from "@mui/icons-material/Home";
import "./stop-component.scss";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { selectShouldShowEditCargoButtons } from "../../../ducks/auth/selectors";
import Button from "@mui/material/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditAddressDialog from "../../SessionsPage/StopPage/edit-dialogs/EditAddressDialog";
import { useRoutePageContext } from "../RoutePageContext";

const StopAddress = ({ stop }: { stop: StopNew }) => {
  const [shouldShowEditAddressDialog, setShouldShowEditAddressDialog] =
    useState(false);
  const shouldShowEditCargoButtons = useSelector(
    selectShouldShowEditCargoButtons
  );

  const address = useMemo(() => {
    return [stop.place.address, stop.place.city, stop.place.postcode]
      .filter(Boolean)
      .join(", ");
  }, [stop]);

  const { reloadData } = useRoutePageContext();

  const googleMapsQuery = encodeURIComponent(
    `${stop.coord.lat},${stop.coord.lon}`
  );

  return (
    <a
      style={{
        textDecoration: "none",
      }}
      href={`https://www.google.com/maps/search/?api=1&query=${googleMapsQuery}`}
    >
      <div className="stop-component">
        {shouldShowEditAddressDialog && (
          <EditAddressDialog
            stopId={stop.id}
            cargoId={stop.cargoId}
            onClose={() => {
              reloadData();
              setShouldShowEditAddressDialog(false);
            }}
          />
        )}
        <HomeIcon className="stop-component-icon" />
        <div className="flex items-center justify-between w-full">
          <div className="flex-grow">{address}</div>
          {shouldShowEditCargoButtons && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setShouldShowEditAddressDialog(true);
              }}
            >
              <EditOutlinedIcon />
            </Button>
          )}
        </div>
      </div>
    </a>
  );
};

export default StopAddress;
