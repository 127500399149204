import { createDriverNotificationTypes } from "dora-contracts";
import * as t from "io-ts";

export const {
  driverNotificationsT,
  driverNotificationT,
  driverNotificationTextT,
} = createDriverNotificationTypes({
  dateTime: t.string,
});

export type DriverNotification = t.TypeOf<typeof driverNotificationT>;
export type DriverNotificationText = t.TypeOf<typeof driverNotificationTextT>;
