import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";

const selectFinanceProductData = (state: RootState) =>
  state.data.finance.financeData;

export const selectProductsAndSurcharges = createSelector(
  selectFinanceProductData,
  (financeData) => [...financeData.products, ...financeData.surcharges]
);

export const selectProducts = createSelector(
  selectFinanceProductData,
  (financeData) => financeData.products
);

export const selectPricingAgreements = (state: RootState) =>
  state.data.finance.pricingAgreements.data;

export const selectUnits = createSelector(
  selectFinanceProductData,
  (financeData) => financeData.units
);

export const selectInvoiceLines = (state: RootState) =>
  state.data.finance.invoiceLines.lines;

export const selectInvoiceLinesLoading = (state: RootState) =>
  state.data.finance.invoiceLines.requestState === "loading";
