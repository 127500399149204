import * as router from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux-store";
import {
  expandRow,
  loadRouteWithStops,
  setShowCompletedDispatcherCreatedStops,
} from "../../ducks/data/route-with-stops";
import DriverCreatedCargos from "./DriverCreatedCargos/DriverCreatedCargos";
import { initialize } from "../../ducks/features";
import DispatcherCreatedStops from "./DispatcherCreatedCargos/DispatcherCreatedStops";
import { loadClients } from "../../ducks/data/clients";
import { loadHideLoadmeterState } from "../../ducks/data/driver-app-settings";
import * as navigationActions from "../../ducks/app/navigation";
import * as sessionActions from "../../ducks/app/session";
import withAuth from "../../components/withAuth";
import { RoutePageContextProvider } from "./RoutePageContext";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  selectShowCompletedDispatcherStops,
  selectStopsAndWaypointsNumberNew,
  showHideCompletedStopsButtonNew,
} from "../../ducks/data/route-with-stops/selectors";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import AddNewCargoDialog from "../AddNewCargoPage";
import { selectMe } from "../../ducks/auth/selectors";
import { loadFinanceDataForDriver } from "../../ducks/data/finance";
import useFeature from "../../hooks/useFeature";

const RoutePage = () => {
  const [showAddNewCargoDialog, setShowAddNewCargoDialog] = useState(false);
  const { routeId, sessionId } = router.useParams();
  const { t } = useTranslation();
  const driverAddInvoiceLinesFeatureEnabled = useFeature(
    "driver-add-invoice-lines"
  );
  const driverProfile = useSelector(selectMe);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      driverAddInvoiceLinesFeatureEnabled &&
      driverProfile?.canAddInvoiceLines
    ) {
      dispatch(loadFinanceDataForDriver());
    }
  }, [dispatch, driverProfile, driverAddInvoiceLinesFeatureEnabled]);

  useEffect(() => {
    dispatch(navigationActions.setCurrentSessionId(sessionId));
    dispatch(sessionActions.loadSession(sessionId || ""));
  }, [dispatch, sessionId]);

  useEffect(() => {
    dispatch(loadRouteWithStops(routeId as string));
    dispatch(loadClients());
    dispatch(loadHideLoadmeterState());
    dispatch(initialize());
  }, [dispatch, routeId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (routeId) {
        dispatch(loadRouteWithStops(routeId as string));
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [routeId, dispatch]);

  const allRelevantRouteStopsNumber = useSelector(
    selectStopsAndWaypointsNumberNew
  );
  const showCompletedDispatcherCreatedStops = useSelector(
    selectShowCompletedDispatcherStops
  );
  const showHideCompletedStopsButton = useSelector(
    showHideCompletedStopsButtonNew
  );

  if (!routeId || !sessionId) {
    return null;
  }

  const onCloseAddNewModal = (result?: { cargoId: string }) => {
    setShowAddNewCargoDialog(false);
    dispatch(loadRouteWithStops(routeId))
      .unwrap()
      .then(() => {
        if (result) {
          dispatch(
            expandRow({ id: result.cargoId, type: "driverCreatedOrder" })
          );
        }
      });
  };

  return (
    <RoutePageContextProvider>
      <div>
        {showAddNewCargoDialog && (
          <AddNewCargoDialog
            sessionId={sessionId}
            onClose={onCloseAddNewModal}
          />
        )}
        <div
          style={{
            position: "fixed",
            bottom: "13vh",
            right: 30,
            background: "var(--primary-500)",
            borderRadius: "50%",
            zIndex: 1,
          }}
        >
          <IconButton
            onClick={() => setShowAddNewCargoDialog(true)}
            style={{ color: "white" }}
          >
            <AddIcon style={{ fontSize: 30 }} />
          </IconButton>
        </div>
        <Typography variant="h5" component="h1">
          {t("MyRoute")}
        </Typography>
        <Typography variant="overline">
          {t("AmountOfStops", {
            count: allRelevantRouteStopsNumber,
          })}
        </Typography>
        <br />
        {showHideCompletedStopsButton && (
          <div className="my-1">
            <Button
              fullWidth
              variant="outlined"
              size="large"
              onClick={() => {
                dispatch(
                  setShowCompletedDispatcherCreatedStops(
                    !showCompletedDispatcherCreatedStops
                  )
                );
              }}
            >
              {showCompletedDispatcherCreatedStops
                ? t("startStopDialog.hideCompletedStops")
                : t("startStopDialog.showCompletedStops")}
            </Button>
          </div>
        )}
        <br />
        <DispatcherCreatedStops />
        <br />
        <br />
        <DriverCreatedCargos />
      </div>
    </RoutePageContextProvider>
  );
};

export default withAuth(RoutePage);
