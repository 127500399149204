import Dialog from "@mui/material/Dialog";
import { Typography } from "@mui/material";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
  selectInvoiceLines,
  selectInvoiceLinesLoading,
  selectPricingAgreements,
  selectProducts,
  selectProductsAndSurcharges,
  selectUnits,
} from "../../../ducks/data/finance/selectors";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { InvoiceLine } from "../../../ducks/data/finance/types";
import { useAppDispatch } from "../../../redux-store";
import {
  createInvoiceLine,
  loadFinanceDataForDriver,
  loadInvoiceLines,
  loadPricingAgreements,
  updateInvoiceLine,
} from "../../../ducks/data/finance";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";

const AddProductModal = (props: {
  cargoDeliveryId: string;
  onClose: () => void;
}) => {
  const { cargoDeliveryId, onClose } = props;
  const { t } = useTranslation("stopPage");
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const products = useSelector(selectProducts);
  const units = useSelector(selectUnits);
  const pricingAgreements = useSelector(selectPricingAgreements);
  const invoiceLines = useSelector(selectInvoiceLines);
  const invoiceLinesLoading = useSelector(selectInvoiceLinesLoading);
  const [quantity, setQuantity] = useState<string | null>(null);
  const [editInvoiceLineId, setEditInvoiceLineId] = useState<string | null>(
    null
  );
  const [selectedProductId, setSelectedProductId] = useState<string | null>(
    null
  );
  const [selectedUnitId, setSelectedUnitId] = useState<string | null>(null);
  const [plusButtonClicked, setPlusButtonClicked] = useState(false);

  const productsOptions = useMemo(() => {
    return products.map((product) => {
      const pricingAgreementForProduct = pricingAgreements.find(
        (pa) => pa.productId === product.id
      );
      return {
        ...product,
        ...(pricingAgreementForProduct && {
          name: pricingAgreementForProduct.name || product.name,
        }),
      };
    });
  }, [products, pricingAgreements]);

  useEffect(() => {
    dispatch(loadFinanceDataForDriver());
    dispatch(loadPricingAgreements({ deliveryId: cargoDeliveryId }));
    dispatch(loadInvoiceLines({ deliveryId: cargoDeliveryId }));
  }, [dispatch, cargoDeliveryId]);

  const onAddClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    const product = products.find((p) => p.id === selectedProductId)!;
    const pricingAgreementForProduct = pricingAgreements.find(
      (pa) => pa.productId === selectedProductId
    );

    dispatch(
      createInvoiceLine({
        deliveryId: cargoDeliveryId,
        line: {
          quantity: quantity ? quantity.replace(",", ".") : "",
          productId: selectedProductId,
          unitId: selectedUnitId,
          description: pricingAgreementForProduct?.name || product.name,
        },
      })
    )
      .unwrap()
      .then(() => {
        setPlusButtonClicked(false);
      })
      .finally(() => {
        setLoading(false);
        resetFormValues();
      });
  };

  const onSaveClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    const product = products.find((p) => p.id === selectedProductId)!;
    const pricingAgreementForProduct = pricingAgreements.find(
      (pa) => pa.productId === selectedProductId
    );
    dispatch(
      updateInvoiceLine({
        deliveryId: cargoDeliveryId,
        lineId: editInvoiceLineId!,
        line: {
          quantity: quantity ? quantity.replace(",", ".") : "",
          productId: selectedProductId,
          unitId: selectedUnitId,
          description: pricingAgreementForProduct?.name || product.name,
        },
      })
    )
      .unwrap()
      .then(() => {
        setPlusButtonClicked(false);
      })
      .finally(() => {
        setLoading(false);
        resetFormValues();
      });
  };

  const resetFormValues = () => {
    setEditInvoiceLineId(null);
    setSelectedProductId(null);
    setSelectedUnitId(null);
    setQuantity("");
  };

  const onEditClick = (id: string) => {
    const invoiceLine = invoiceLines.find((il) => il.id === id)!;
    setEditInvoiceLineId(id);
    setSelectedProductId(invoiceLine.productId);
    setSelectedUnitId(invoiceLine.unitId);
    setQuantity(invoiceLine.quantity || "");
  };

  const shouldShowForm = useMemo(() => {
    return invoiceLines.length === 0 || plusButtonClicked;
  }, [invoiceLines, plusButtonClicked]);

  return (
    <Dialog open onClose={onClose}>
      <div className="px-6 py-4">
        <Typography variant="h6" component="h2" pt={2}>
          {t("addProduct.heading")}
        </Typography>
        <div className="flex flex-col gap-2">
          {invoiceLines.map((il) => (
            <InvoiceLineRow
              key={il.id}
              invoiceLine={il}
              onEditClick={() => onEditClick(il.id)}
            />
          ))}
        </div>
        <br />

        {invoiceLinesLoading ? (
          <div>Loading ...</div>
        ) : shouldShowForm ? (
          <form onSubmit={editInvoiceLineId ? onSaveClick : onAddClick}>
            <Grid item xs={12}>
              <Autocomplete
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    autoFocus
                    margin="dense"
                    label={t("addProduct.product")}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                options={productsOptions}
                value={
                  productsOptions.find(
                    (product) => product.id === selectedProductId
                  ) || null
                }
                onChange={(_, v) => setSelectedProductId(v?.id || null)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="dense"
                    label={t("addProduct.unit")}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>{option.name}</li>
                )}
                options={units}
                value={units.find((unit) => unit.id === selectedUnitId) || null}
                onChange={(_, v) => setSelectedUnitId(v?.id || null)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("addProduct.quantity")}
                margin="dense"
                fullWidth
                value={quantity}
                required
                type="number"
                InputProps={{
                  componentsProps: {
                    input: {
                      inputMode: "decimal",
                    },
                  },
                }}
                InputLabelProps={{ shrink: !!quantity }}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="flex gap-2 justify-between w-100 mt-4">
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={onClose}
                >
                  {t("notes.backButtonLabel")}
                </Button>
                <LoadingButton
                  loading={loading}
                  disabled={!selectedProductId}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  <span>
                    {editInvoiceLineId
                      ? t("addProduct.saveButtonLabel")
                      : t("addProduct.addButtonLabel")}
                  </span>
                </LoadingButton>
              </div>
            </Grid>
          </form>
        ) : (
          <Button
            fullWidth
            onClick={() => setPlusButtonClicked(true)}
            className="flex gap-2 items-center justify-center"
          >
            <AddIcon />
            <span>{t("addProduct.addMore")}</span>
          </Button>
        )}
      </div>
    </Dialog>
  );
};

const InvoiceLineRow = (props: {
  invoiceLine: InvoiceLine;
  onEditClick: () => void;
}) => {
  const { t } = useTranslation("stopPage");
  const { invoiceLine, onEditClick } = props;
  const productsAndSurcharges = useSelector(selectProductsAndSurcharges);
  const units = useSelector(selectUnits);
  const product = productsAndSurcharges.find(
    (p) => p.id === invoiceLine.productId
  );
  const productName = product?.name || "";
  const unit = invoiceLine.unitId
    ? units.find((u) => u.id === invoiceLine.unitId)?.name
    : "";

  let elements = [];
  if (productName) {
    elements.push(productName);
  }
  if (unit) {
    elements.push(`${t("addProduct.unit")}: ${unit}`);
  }
  if (invoiceLine.quantity) {
    elements.push(
      `${t("addProduct.quantity")}: ${invoiceLine.quantity.toString()}`
    );
  }

  return (
    <div className="flex justify-between items-center">
      <div>{elements.join(", ")}</div>
      <Button
        onClick={onEditClick}
        style={{ paddingRight: 0, justifyContent: "end" }}
        disabled={invoiceLine.locked}
      >
        <EditOutlinedIcon />
      </Button>
    </div>
  );
};

export default AddProductModal;
