import { RootState } from "../../redux-store";
import { createSelector } from "@reduxjs/toolkit";
import {
  selectArrivalDepartureButtonsEnabledForCustomer,
  selectCubicMetersEnabledForCustomer,
} from "../app/session/selectors";
import { selectFeature } from "../features/selectors";

export const selectStatus = (state: RootState) => state.auth.status;

export const selectLanguage = (state: RootState) =>
  state.auth.me?.defaultLanguage || "da";

export const selectMe = (state: RootState) => state.auth.me;
export const selectAuthMode = (state: RootState) => state.auth.authMode;

export const selectDefaultCargoType = createSelector(
  selectMe,
  (me) => me?.defaultCargoType || null
);

export const selectShouldShowCompleteStopButton = createSelector(
  selectMe,
  (me) => !me?.collectedDeliveredButtonsRemovedForOrg
);

export const selectShouldShowEditCargoButtons = (state: RootState) => {
  const featureEnabled = selectFeature("driver-update-cargo")(state);
  return featureEnabled && state.auth.me?.canEditCargos;
};

export const selectShouldShowAddProductButton = (state: RootState) => {
  const featureEnabled = selectFeature("driver-add-invoice-lines")(state);
  return featureEnabled && state.auth.me?.canAddInvoiceLines;
};

export const selectShouldShowArrivalDepartureButtons =
  (stopId: string) => (state: RootState) => {
    // const featureEnabled = selectFeature("arrived-departed-driver-buttons")(
    //   state
    // );
    const enabledForOrg =
      !!selectMe(state)?.arrivedDepartedButtonsDefaultEnabledForOrg;
    const enabledForCustomer =
      selectArrivalDepartureButtonsEnabledForCustomer(stopId)(state);

    // if (!featureEnabled) {
    //   return false;
    // }
    if (!enabledForOrg) {
      return false;
    }
    if (enabledForCustomer === null) {
      // either not set on customer OR no customer for cargo
      return true;
    }
    return enabledForCustomer;
  };

export const selectShouldShowCubicMeters =
  (stopId: string) => (state: RootState) => {
    const featureEnabled = selectFeature("driver-add-cubic-meters")(state);
    const enabledForOrg =
      !!selectMe(state)?.cubicMetersButtonDefaultEnabledForOrg;
    const enabledForCustomer =
      selectCubicMetersEnabledForCustomer(stopId)(state);

    if (!featureEnabled) {
      return false;
    }
    if (!enabledForOrg) {
      return false;
    }
    if (enabledForCustomer === null) {
      // either not set on customer OR no customer for cargo
      return true;
    }
    return enabledForCustomer;
  };
