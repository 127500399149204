import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../redux-store";

const selectNotificationsState = (state: RootState) => state.app.notifications;

export const selectNotificationInView = createSelector(
  selectNotificationsState,
  (notif) => {
    const notifInView =
      notif.keepNotificationWithIdInView &&
      notif.notifications.find(
        (n) => n.id === notif.keepNotificationWithIdInView
      );
    if (notifInView) {
      return notifInView;
    }
    if (notif.notifications.length) {
      return notif.notifications[notif.notifications.length - 1];
    }
    return null;
  }
);

export const selectMarkNotificationAsSeenInProgress = createSelector(
  selectNotificationsState,
  (notif) => notif.markAsSeenInProgress
);
