import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api, { createDecoder, noContentDecoder } from "../../api";
import { driverNotificationsT, DriverNotification } from "./types";

interface State {
  notifications: DriverNotification[];
  markAsSeenInProgress: boolean;
  keepNotificationWithIdInView: string | null;
}

const initialState: State = {
  markAsSeenInProgress: false,
  keepNotificationWithIdInView: null,
  notifications: [],
};

const prefix = "app/notifications";

export const loadNotifications = createAsyncThunk(
  `${prefix}/fetch-unseen-notifications`,
  async (_, thunkAPI) => {
    return api(thunkAPI).get({
      url: "/drivers-api/notifications",
      decoder: createDecoder(driverNotificationsT),
    });
  }
);

export const markNotificationAsSeen = createAsyncThunk(
  `${prefix}/mark-notification-as-seen`,
  async (notificationId: string, thunkAPI) => {
    // TODO: consider having a min wait/request time 2 sec or so, for the loading spinner to be visible
    return api(thunkAPI).post({
      url: `/drivers-api/notifications/${notificationId}/mark-as-seen`,
      body: {},
      decoder: noContentDecoder,
    });
  }
);

const slice = createSlice({
  name: "app/notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
    builder.addCase(markNotificationAsSeen.pending, (state, action) => {
      state.markAsSeenInProgress = true;
      state.keepNotificationWithIdInView = action.meta.arg;
    });
    builder.addCase(markNotificationAsSeen.rejected, (state) => {
      state.markAsSeenInProgress = false;
      // TODO: notify driver of error? Happens automatically?
    });
    builder.addCase(markNotificationAsSeen.fulfilled, (state, action) => {
      state.markAsSeenInProgress = false;
      state.keepNotificationWithIdInView = null;
      state.notifications = state.notifications.filter(
        (n) => n.id !== action.meta.arg
      );
    });
  },
});

export default slice.reducer;
