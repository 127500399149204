import { isMobile } from "./mobile";
import { ACTIVE_ORG_REF_LOCAL_STORAGE_KEY } from "./organizationStorage";

const apiUrlStaging = (window as any).env?.apiUrlStaging;
const apiUrlProd = (window as any).env?.apiUrlProd;
const apiRootProd = apiUrlProd ? new URL(apiUrlProd) : undefined;
const apiRootStaging = apiUrlStaging ? new URL(apiUrlStaging) : undefined;

const apiRootLocalStorageKey = "apiRoot";
export const ENVIRONMENT_PROD = "prod" as const;
export const ENVIRONMENT_STAGING = "staging" as const;

let environment =
  localStorage.getItem(apiRootLocalStorageKey) ?? ENVIRONMENT_PROD;

export const getEnvironment = () => {
  return environment;
};

export const setApiEnvironment = (
  newEnvironment: typeof ENVIRONMENT_STAGING | typeof ENVIRONMENT_PROD
) => {
  environment = newEnvironment;
  localStorage.setItem(apiRootLocalStorageKey, newEnvironment);
};

let organization: string | undefined =
  localStorage.getItem(ACTIVE_ORG_REF_LOCAL_STORAGE_KEY) ?? undefined;

export const setOrganizationRef = (ref: string | undefined) => {
  organization = ref;
  if (ref) {
    localStorage.setItem(ACTIVE_ORG_REF_LOCAL_STORAGE_KEY, ref);
  } else {
    localStorage.removeItem(ACTIVE_ORG_REF_LOCAL_STORAGE_KEY);
  }
};

export const getApiRoot = () => {
  if (!isMobile) {
    return "";
  }

  if (!apiRootProd || !apiRootStaging) {
    throw new Error(
      "VITE_API_ROOT_STAGING and VITE_API_ROOT_PROD must be set on mobile"
    );
  }
  const val =
    environment === "staging" ? apiRootStaging.origin : apiRootProd.origin;
  console.log("environment", environment, "value", val, apiRootStaging);
  return val;
};

export const apiFetch = async (
  input: RequestInfo,
  init?: RequestInit
) => {
  const hasQuery = (input as string).includes("?");
  const url = isMobile
    ? `${getApiRoot()}${(input as string).startsWith("/drivers-api") ? `/native-app${input}` : input}${hasQuery ? `&loginOrgRef=${organization}` : `?loginOrgRef=${organization}`}`
    : input;
  return fetch(url, init);
};
