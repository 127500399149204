import { SyntheticEvent, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { Box, TextField, Typography, Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { useDropoffPage, AddDropoff } from "../../ducks/app/add-cargo/hooks";
import PlaceSearch from "../AddNewCargoPage/PlaceSearch/PlaceSearch";
import CargoInfo from "./StopPage/CargoInfo";
import { Cargo } from "../../ducks/app/session/types";
import StopDateTimeFields from "../../components/StopDateTimeFields";
import { selectHideLoadmeters } from "../../ducks/data/driver-app-settings/selectors";
import { useAppSelector } from "../../redux-store";
import { SearchOption } from "../AddNewCargoPage/PlaceSearch/SearchOption";
import { selectCargoPickup } from "../../ducks/app/session/selectors";
import Dialog from "@mui/material/Dialog";

const DropoffFormInner = ({
  cargo,
  addDropoff,
  onSuccess,
  onCancel,
}: {
  cargo: Cargo;
  addDropoff: AddDropoff;
  onSuccess: (stopId: string) => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation("addDropoffPage");
  const [lm, setLm] = useState(cargo.undeliveredLm);
  const now = useMemo(() => DateTime.now(), []);
  const [dropoffTime, setDropoffTime] = useState(now);
  const [loading, setLoading] = useState(false);
  const [dropoffLocation, setDropoffLocation] = useState<SearchOption | null>(
    null
  );
  const hideLoadmeters = useAppSelector(selectHideLoadmeters);
  const cargoPickup = useAppSelector(selectCargoPickup(cargo.id));
  const [pickupDropoffTimeError, setPickupDropoffTimeError] = useState(false);
  const [pickupDropoffDateError, setPickupDropoffDateError] = useState(false);

  const onClick = async (e: SyntheticEvent) => {
    setPickupDropoffTimeError(false);
    setPickupDropoffDateError(false);
    e.preventDefault();
    if (!dropoffLocation || !dropoffTime) {
      return;
    }
    if (cargoPickup?.date && dropoffTime.toISODate() < cargoPickup.date) {
      setPickupDropoffDateError(true);
      return;
    }
    if (
      cargoPickup?.time &&
      cargoPickup.time > dropoffTime.toFormat("HH:mm") &&
      cargoPickup.date! >= dropoffTime.toISODate()
    ) {
      setPickupDropoffTimeError(true);
      return;
    }
    setPickupDropoffTimeError(false);
    // TODO: time validation with pickup
    try {
      setLoading(true);
      if (!dropoffLocation) {
        return;
      }
      const loc = await (async () => {
        switch (dropoffLocation.type) {
          case "REVERSE_GEOCODE_OPTION":
          case "SEARCH_RESULT":
            const lookupData = await dropoffLocation.value.data.lookup();
            return {
              googlePlaceId: dropoffLocation.value.data.id,
              placeName: lookupData.placeName || "",
              country: lookupData.countryCode,
              position: lookupData.coords,
              address: lookupData.address,
              city: lookupData.city,
              postcode: lookupData.postcode,
            };
          case "SAVED_LOCATION": {
            return {
              placeName: dropoffLocation.value.data.place.placeName || "",
              country: dropoffLocation.value.data.place.country,
              position: dropoffLocation.value.data.place.coord,
              address: dropoffLocation.value.data.place.address,
              city: dropoffLocation.value.data.place.city,
              postcode: dropoffLocation.value.data.place.postcode,
            };
          }
        }
      })();
      const result = await addDropoff({
        lm,
        time: dropoffTime,
        dropoffLocation: loc,
      });
      onSuccess(result.stopId);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box component="form" onSubmit={onClick}>
        {!hideLoadmeters && (
          <TextField
            label={t("loadmeterField")}
            margin="dense"
            fullWidth
            value={lm}
            required
            InputProps={{
              componentsProps: {
                input: {
                  inputMode: "decimal",
                },
              },
            }}
            onChange={(e) => setLm(e.target.value)}
          />
        )}
        <StopDateTimeFields
          dateFieldLabel={t("dropoffDateFieldLabel")}
          timeFieldLabel={t("dropoffTimeFieldLabel")}
          value={dropoffTime}
          timeError={pickupDropoffTimeError}
          dateError={pickupDropoffDateError}
          onChange={setDropoffTime}
        />
        <PlaceSearch
          label={t("dropoffPlaceFieldLabel")}
          location={dropoffLocation}
          onLocationChange={setDropoffLocation}
        />
        <Stack spacing={2} mt={2}>
          <LoadingButton
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            loading={loading}
          >
            {t("saveStopButtonText")}
          </LoadingButton>
          <Button variant="outlined" size="large" fullWidth onClick={onCancel}>
            {t("cancelButtonLabel")}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export const AddDropoffForm = ({
  cargoId,
  sessionId,
  onCancel,
  onSuccess,
  skipCompleteEvent = false,
}: {
  sessionId: string;
  cargoId: string;
  onCancel: () => void;
  onSuccess: (stopId: string) => void;
  skipCompleteEvent?: boolean;
}) => {
  const dropoffPage = useDropoffPage({ sessionId, cargoId });
  const cargo = dropoffPage.cargo;
  if (!cargo) {
    return null;
  }
  return (
    <DropoffFormInner
      cargo={cargo}
      addDropoff={(input) => dropoffPage.addDropoff(input, skipCompleteEvent)}
      onCancel={onCancel}
      onSuccess={onSuccess}
    />
  );
};

const AddDropoffDialog = ({
  sessionId,
  cargoId,
  onClose,
  onSuccess,
  skipCompleteEvent = false,
}: {
  sessionId: string;
  cargoId: string;
  onClose: () => void;
  onSuccess: (stopId: string) => void;
  skipCompleteEvent?: boolean;
}) => {
  const { t } = useTranslation("addDropoffPage");
  return (
    <Dialog open fullScreen onClose={onClose}>
      <Box
        className="px-4 py-2"
        sx={{
          paddingTop: "env(safe-area-inset-top, 16px)",
          mt: 3,
        }}
      >
        <Typography component="h1" variant="h5">
          {t("pageHeading")}
        </Typography>
        <CargoInfo cargoId={cargoId} stopId={null} />
        <AddDropoffForm
          sessionId={sessionId}
          cargoId={cargoId}
          onCancel={onClose}
          onSuccess={onSuccess}
          skipCompleteEvent={skipCompleteEvent}
        />
      </Box>
    </Dialog>
  );
};

export default AddDropoffDialog;
