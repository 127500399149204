import { RouteWaypointStopNew } from "../../../ducks/data/route-with-stops/types";
import { useMemo } from "react";
import "./DispatcherCreatedStop.scss";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { IconButton } from "@mui/material";

const DispatcherCreatedWaypointStop = ({
  waypointStop,
}: {
  waypointStop: RouteWaypointStopNew;
}) => {
  const waypointPlace = useMemo(() => {
    const placeComponents = [
      waypointStop.waypoint.place.placeName,
      waypointStop.waypoint.place.city,
      waypointStop.waypoint.place.postcode,
    ];
    return placeComponents.filter(Boolean).join(", ");
  }, [waypointStop]);

  const googleMapsQuery = encodeURIComponent(
    `${waypointStop.waypoint.place.coord.lat},${waypointStop.waypoint.place.coord.lon}`
  );

  return (
    <div className="dispatcher-created-stop flex justify-between items-top">
      <div className="my-2">
        <div className="stop-title">{waypointPlace}</div>
        <div className="stop-date">{waypointStop.waypoint.note}</div>
      </div>
      <div>
        <IconButton
          onClick={() =>
            window.open(
              `https://www.google.com/maps/search/?api=1&query=${googleMapsQuery}`,
              "_blank"
            )
          }
        >
          <LocationOnOutlinedIcon className="stop-component-icon" />
        </IconButton>
      </div>
    </div>
  );
};

export default DispatcherCreatedWaypointStop;
