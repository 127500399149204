import React, { SyntheticEvent, useState } from "react";
import { Box, Typography, Grid, TextField, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import { Stop } from "../../../ducks/app/session/types";
import { addInvoiceReference, loadSession } from "../../../ducks/app/session";
import { useAppDispatch } from "../../../redux-store";
import Dialog from "@mui/material/Dialog";
import { useSelector } from "react-redux";
import { selectCurrentRouteId } from "../../../ducks/data/route-with-stops/selectors";
import { loadRouteWithStops } from "../../../ducks/data/route-with-stops";

const AddRefModal = (props: {
  sessionId: string;
  stop: Stop;
  onClose: () => void;
}) => {
  const { sessionId, stop, onClose } = props;
  const [ref, setRef] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("stopPage");

  const currentRouteId = useSelector(selectCurrentRouteId);

  const onAddClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        addInvoiceReference({
          sessionId,
          cargoId: stop.cargo_id,
          stopId: stop.id,
          ref,
        })
      );
      dispatch(loadSession(sessionId));
      dispatch(loadRouteWithStops(currentRouteId!));
      onClose();
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog open onClose={onClose}>
      <div className="px-8 py-4">
        <Typography variant="h6" component="h2" pt={2}>
          {t("ref.heading")}
        </Typography>
        <Box component="form" mt={2} onSubmit={onAddClick}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                type="text"
                label={t("ref.textFieldLabel")}
                placeholder="ABC123"
                onChange={(e) => {
                  setRef(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="flex gap-2 justify-between w-100 mt-4">
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={onClose}
                >
                  {t("ref.backButtonLabel")}
                </Button>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                >
                  {t("ref.addButtonLabel")}
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Dialog>
  );
};

export default AddRefModal;
