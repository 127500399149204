import { useState } from "react";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import AddProductModal from "./AddProductModal";
import AddIcon from "@mui/icons-material/Add";

const AddProductModalAndButton = (input: { cargoDeliveryId: string }) => {
  const { cargoDeliveryId } = input;
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation(["stopPage"]);

  return (
    <>
      {showDialog && (
        <AddProductModal
          cargoDeliveryId={cargoDeliveryId}
          onClose={() => setShowDialog(false)}
        />
      )}
      <Button
        style={{
          background: "var(--success-500)",
          border: "1px solid var(--success-500)",
          color: "white",
        }}
        onClick={() => setShowDialog(true)}
        variant="outlined"
        size="large"
        startIcon={<AddIcon />}
      >
        {t("stopPage:addProduct.buttonText")}
      </Button>
    </>
  );
};

export default AddProductModalAndButton;
