import {
  createFinanceDataForDriver,
  createDriverInvoiceLinesTypes,
} from "dora-contracts";
import * as t from "io-ts";

const { financeDataT, pricingAgreementT, pricingAgreementsT } =
  createFinanceDataForDriver({
    decimalT: t.string,
  });

const { lineT, linesT, createUpdateLineT } = createDriverInvoiceLinesTypes({
  decimalT: t.string,
});

export type Product = t.TypeOf<typeof financeDataT>["products"][0];
export type Surcharge = t.TypeOf<typeof financeDataT>["surcharges"][0];
export type FinanceUnit = t.TypeOf<typeof financeDataT>["units"][0];
export type PricingAgreement = t.TypeOf<typeof pricingAgreementT>;
export type InvoiceLine = t.TypeOf<typeof lineT>;
export type CreateUpdateInvoiceLine = t.TypeOf<typeof createUpdateLineT>;

export { financeDataT, lineT, linesT, createUpdateLineT, pricingAgreementsT };
